import React, {useState, useEffect} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";

export const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
      e.preventDefault();
      email &&
      email.indexOf("@") > -1 &&
      onValidated({
          EMAIL: email,
      });
  }
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <p className = "font-bold text-[40px] md:text-[50px] lg:text-[70px] leading-[50px] lg:leading-[70px]"> The First Step <br/>To Maximizing Your<br className="md:inline"/> Finances</p>
      <p className = "font-normal text-[16] mt-[30px]"> Join our waitlist and be one of the first to access our products</p>
      <div className = "mt-[40px] flex rounded-md shadow-sm h-[75px]">
      <span className="inline-flex items-center rounded-l-md border border-0 border-gray-300 text-sm bg-white">
        <img src = "/images/24@2x.png" className = "ml-[24px] mr-[20px] w-[24px]" alt = "avax"/>
      </span>
      <input 
        type="email" 
        name="b_email" 
        tabindex="-1" 
        value={email}
        onChange = {(e) => setEmail(e.target.value)}
        id="b_email" 
        className="flex-1 block w-full rounded-none sm:text-sm border-0 rounded-r-md xl:rounded-none focus:outline-none text-black" 
        placeholder="your email address"  
      />
      <span className="hidden xl:inline-flex  items-center rounded-r-md border border-0 border-gray-300 bg-white text-sm">
        <button type="submit" className = "bg-black rounded-md mr-[10px] w-[100px] sm:w-[150px] h-[45px] text-white">Join the waitlist </button>
      </span>
      </div>
      <button type="submit" className = "xl:hidden bg-black rounded-md w-[100%] mt-[15px] h-[45px] text-white">Join the waitlist </button>
      {status === "sending" && (
        <div 
          className="pt-[10px]"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "error" && (

        <p className="pt-[10px]" >{!isNaN(parseFloat(message.charAt(0))) ? message.slice(4, message.length) : message}</p>
      )}
      {status === "success" && (
        <div 
          className="pt-[10px]"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </form>
  );
};