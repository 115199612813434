import React from "react";
import { useState } from "react";
import { CustomForm } from "./components/CustomMCForm";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import './App.css';

function App() {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const postUrl = `https://finance.us14.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  }

  return (
    <div className = "overflow-hidden container-fluid font-sans min-h-screen home__background">
      <div className={` ${isMobileNavOpen ? 'bottom-[0]' : 'hidden'} absolute top-[0] right-[0] left-[0] z-10 mobile__cover transition-all`} onClick={() => toggleMobileNav()}/>
      <div className='top-0 left-0 w-full h-12 z-20'>
        <div 
          className={
            `
            relative 
            container pt-[19px] md:pt-[35px] pl-[35px] pr-[35px] 
            h-[41px] md:h-[75px]
            flex items-center flex-col md:justify-between md:flex-row md:h-full 
            text-white gap-y-11 md:gap-7
            transition-all
            overflow-hidden
            md:overflow-visible
            z-10
            ${isMobileNavOpen ? 'h-[168px] bg-white' : ''}

            `
            }
          // style={{height: `${isMobileNavOpen ? '168px' : ''}`, backgroundColor: `${isMobileNavOpen ? '#FFF' : 'transparent'}`}}
          >
          <img src = {`${isMobileNavOpen ? '/images/logo-on-white@2x.png' : '/images/logo-on-blue@2x.png'}`} className = "w-[170px] md:w-[236px] z-20" alt = "logo"/>
          <ul className=" flex flex-row gap-6 md:gap-2 transition-all z-20">
              <li>
                  <a href="https://t.me/MaxxFInance" target="_blank" rel="noreferrer"><img src = "/images/Telegram.svg" className = "mt-0.5 w-[46px] md:w-[35px] hover:invert hover:cursor-pointer" alt = "avax"/></a>
              </li>
              <li>
                <a href="https://twitter.com/MaxxFinance" target="_blank" rel="noreferrer"><img src = "/images/T32.png" className = "mt-0.5 w-[46px] md:w-[35px] hover:invert hover:cursor-pointer" alt = "avax"/></a>
              </li>
              <li>
                <a href="https://discord.gg/7DUahA7B" target="_blank" rel="noreferrer"><img src = "/images/D32.png" className = "mt-0.5 w-[46px] md:w-[35px] hover:invert hover:cursor-pointer" alt = "avax"/></a>
              </li>
          </ul>
          <button className="absolute p-[7px] left-[28px] top-[20.5px] z-20 md:hidden" onClick={() => toggleMobileNav()}>
            <img src = "./images/burger@3x.svg" className = {`${isMobileNavOpen ? 'hidden' : 'z-10 relative'} w-[18px]`} alt="open menu button"/>
            <img src = "./images/close@3x.svg" className = {`${isMobileNavOpen ? '' : 'hidden'} w-[18px]`} alt="close menu button"/>
          </button>
        </div>
      </div>
      <div>
        <div className='container pt-[59px] md:pt-[155px] pl-[35px] pr-[35px] flex xl:flex-row flex-col justify-between items-center h-full text-white gap-14'>
          <MailchimpSubscribe 
            url={postUrl}
            render={({ subscribe, status, message }) => (
              <CustomForm
                  status={status} 
                  message={message}
                  onValidated={formData => subscribe(formData)}
              />
            )}
          />
          {/* sample product */}
          <div className = "inline-block relative xl:mr-[100px] 2xl:mr-[150px] mt-[40px] mb-[40px] mr-[50px] ml-[50px] shadow-sm shadow-2xl">
            <div className =" w-[330px] sm:w-[436px] relative">
              <p className="absolute text-black left-[29px] top-[25px] text-lg font-bold">Estimate your reward</p>
              <img src = "/images/ModuleCopy@2x.png" className = "w-full" alt = "avax"/>
              <div className="absolute bottom-[25px] px-[29px] w-full flex justify-between">
                <div>
                  <p className="text-[12px] font-semibold" >Your estimated rewards</p>
                  <p className="font-bold" >269,246 MAXX</p>
                </div>
                <div>
                  <p className="text-[12px] font-semibold" >Current APR</p>
                  <p className="font-bold" >49%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
